import { resolvePromisifiedAction } from './store/promisified-actions-actions';

export function createPromisifiedAction(
  action,
  dataSelector = () => console.error('dataSelector is required'),
  errorSelector = (e) => e,
) {
  return (args, correlationId) => (dispatch) => {
    dispatch(action(...args)).then(
      (result) => {
        return dispatch(
          resolvePromisifiedAction(true, correlationId, dataSelector(result)),
        );
      },
      (err) => {
        return dispatch(
          resolvePromisifiedAction(false, correlationId, errorSelector(err)),
        );
      },
    );
  };
}
