import { ThunkDispatch } from 'redux-thunk';
import {
  getPostFeedMetadataPage,
  getPostFeedPage,
} from '@wix/ambassador-blog-frontend-adapter-public-v2-post-feed-page/http';
import {
  FeedType,
  Metrics,
  Post as PostNullable,
} from '@wix/ambassador-blog-frontend-adapter-public-v2-post-feed-page/types';
import {
  DeepRequired,
  ENTITY_TYPE_POSTS,
  SECTION_CATEGORY,
  SECTION_HOMEPAGE,
  SECTION_TAGS,
  fetchAppData,
  fetchAppDataSuccess,
  fetchTPASettingsSuccess,
  fetchTagsSuccess,
  getCategoryBySlug,
  getCategoryIds,
  getDefaultSiteLocale,
  getLocales,
  normalizeCategory,
  resolveId,
} from '@wix/communities-blog-client-common';
import {
  fetchCategoryPostsFailure,
  fetchCategoryPostsRequest,
  fetchCategoryPostsSuccess,
} from '../../common/actions/fetch-category-posts';
import {
  fetchFeedPostsFailure,
  fetchFeedPostsRequest,
  fetchFeedPostsSuccess,
} from '../../common/actions/fetch-feed-posts';
import { createPromisifiedAction } from '../../common/actions-promisifier/create-promisified-action';

import { getQueryLocale } from '../../common/selectors/locale-selectors';
import { getPageSize } from '../../common/selectors/pagination-selectors';
import { normalizePosts } from '../../common/services/post-utils';
import { setUser } from '../../common/store/auth/set-user';
import { fetchCategoriesSuccess } from '../../common/store/categories/fetch-categories';
import { fetchPaywallBanner } from '../../common/store/paywall/paywall-actions';
import {
  fetchTranslations,
  fetchTranslationsSuccess,
} from '../../common/store/translations/translations-actions';
import { AppState } from '../../common/types';
import { FeedPageThunkAction } from '../types';

type Post = DeepRequired<PostNullable>;

const feedTypeToSectionMap: Record<
  FeedType.ALL_POSTS | FeedType.CATEGORY | FeedType.TAG,
  string
> = {
  [FeedType.ALL_POSTS]: SECTION_HOMEPAGE,
  [FeedType.CATEGORY]: SECTION_CATEGORY,
  [FeedType.TAG]: SECTION_TAGS,
};

type FetchFeedRenderModelParams = {
  includeContent: boolean;
  includeInitialPageData: boolean;
  language: string;
  page: number;
  pageSize?: number;
  translationsName?: string;
  /** Slug or UUID */
  category?: string;
};

export const fetchFeedRenderModel = fetchBaseRenderModel({
  feedType: FeedType.ALL_POSTS,
  onInit: ({ dispatch, page }) => {
    dispatch(fetchFeedPostsRequest(ENTITY_TYPE_POSTS, page));
  },
  onSuccess: ({ state, posts, entityCount, page, pageSize }) => {
    return fetchFeedPostsSuccess(
      normalizePosts({
        state,
        posts,
        blogCategoryIds: getCategoryIds(state),
        origin: '/v3/posts',
      }),
      {
        page,
        entityType: ENTITY_TYPE_POSTS,
        entityCount,
        pageSize,
      },
    );
  },
  onError: ({ page }) => {
    return fetchFeedPostsFailure(ENTITY_TYPE_POSTS, page);
  },
});

export const fetchCategoryFeedRenderModel = fetchBaseRenderModel({
  feedType: FeedType.CATEGORY,
  onInit: ({ dispatch, page, state, categorySlug }) => {
    dispatch(
      fetchCategoryPostsRequest({
        categoryId: getCategoryBySlug(state, categorySlug),
        page,
      }),
    );
  },
  onSuccess: ({ state, posts, entityCount, page, pageSize, categorySlug }) => {
    const categoryId = resolveId(getCategoryBySlug(state, categorySlug));

    return fetchCategoryPostsSuccess(
      normalizePosts({
        state,
        posts,
        blogCategoryIds: [...getCategoryIds(state), categoryId],
        origin: '/v3/posts',
      }),
      {
        categoryId,
        page,
        entityCount,
        pageSize,
      },
    );
  },
  onError: ({ page, status, state, categorySlug }) => {
    const categoryId = resolveId(getCategoryBySlug(state, categorySlug));

    return fetchCategoryPostsFailure(
      { error: { status }, categoryId, page },
      { categoryId },
    );
  },
});

export const fetchFeedPostsWithAdapterPromisified = createPromisifiedAction(
  fetchFeedRenderModel,
  () => null,
  (response) => response.status,
);

export const fetchCategoryPostsWithAdapterPromisified = createPromisifiedAction(
  fetchCategoryFeedRenderModel,
  () => null,
  (response) => response.status,
);

type InitPayload = {
  dispatch: ThunkDispatch<any, any, any>;
  page: number;
  state: AppState;
  categorySlug: string;
};

type SuccessPayload = {
  state: AppState;
  posts: Post[];
  entityCount: number;
  page: number;
  pageSize: number;
  categorySlug: string;
};

type ErrorPayload = {
  page: number;
  status: number;
  state: AppState;
  categorySlug: string;
};

function fetchBaseRenderModel(context: {
  feedType: FeedType.ALL_POSTS | FeedType.CATEGORY | FeedType.TAG;
  onInit: (payload: InitPayload) => any;
  onSuccess: (payload: SuccessPayload) => any;
  onError: (payload: ErrorPayload) => any;
}) {
  return ({
      includeContent,
      includeInitialPageData,
      language,
      page = 1,
      pageSize: defaultPageSize,
      category,
      translationsName,
    }: FetchFeedRenderModelParams): FeedPageThunkAction =>
    async (dispatch, getState, { httpClient }) => {
      const section = feedTypeToSectionMap[context.feedType];

      try {
        const state = getState();

        context.onInit({ dispatch, page, state, categorySlug: category ?? '' });

        const locale = getQueryLocale(state);
        const languageCode =
          locale ||
          language ||
          (getLocales(state) && getDefaultSiteLocale(state)?.id) ||
          null;
        const pageSize = defaultPageSize ?? getPageSize(state, { section });

        const [feedResponse, metadataResponse] = await Promise.all([
          httpClient
            .request(
              getPostFeedPage({
                includeContent,
                includeInitialPageData,
                categoryOptions: { id: category },
                languageCode,
                locale,
                page,
                pageSize,
                translationsName,
                type: context.feedType,
              }),
            )
            .then((r) => r.data as DeepRequired<typeof r.data>),
          httpClient
            .request(
              getPostFeedMetadataPage({
                page,
                pageSize,
                languageCode,
                locale,
                categoryOptions: { id: category },
                type: context.feedType,
              }),
            )
            .then((r) => r.data as DeepRequired<typeof r.data>),
        ]);

        const { currentUser, posts, tags, settings, translations, appData } =
          feedResponse.postFeedPage;

        const categories = feedResponse.postFeedPage.categories.map((c) =>
          normalizeCategory(c),
        );

        const postsWithMetadata = enhancePostsWithMetadata(
          feedResponse.postFeedPage.posts.posts,
          metadataResponse.postFeedMetadataPage.postMetrics,
        );

        return Promise.all(
          includeInitialPageData
            ? [
                currentUser?.id
                  ? dispatch(setUser(currentUser))
                  : Promise.resolve(),
                dispatch(fetchTPASettingsSuccess(settings)),
                dispatch(fetchCategoriesSuccess(categories)),
                dispatch(fetchAppDataSuccess(appData)),
                dispatch(fetchTranslationsSuccess(translations)),
                dispatch(fetchTagsSuccess(tags)),
                dispatch(
                  context.onSuccess({
                    state: { categories, translations },
                    posts: postsWithMetadata,
                    entityCount: posts.metaData?.total,
                    page,
                    pageSize,
                    categorySlug: category ?? '',
                  }),
                ),
                dispatch(fetchPaywallBanner()),
              ]
            : [
                dispatch(
                  context.onSuccess({
                    state,
                    posts: postsWithMetadata,
                    entityCount: posts.metaData?.total,
                    page,
                    pageSize,
                    categorySlug: category ?? '',
                  }),
                ),
              ],
        );
      } catch (err) {
        return Promise.all([
          dispatch(
            context.onError({
              page,
              status: (err as any)?.response?.status,
              state: getState(),
              categorySlug: category ?? '',
            }),
          ),

          // Load appData and translations as fallback
          dispatch(fetchAppData(undefined)),
          dispatch(fetchTranslations(language)),
        ]);
      }
    };
}

const enhancePostsWithMetadata = (
  posts: Post[],
  postMetricsMap: Record<string, DeepRequired<Metrics>>,
): Post[] => {
  const defaultMetrics: DeepRequired<Metrics> = {
    averageRating: 0,
    comments: 0,
    likes: 0,
    totalRatings: 0,
    views: 0,
  };

  return posts.map((p) => ({
    ...p,
    metrics: postMetricsMap[p.id] || defaultMetrics,
  }));
};
