export function normalizeCategory(category) {
    if ('postAmount' in category) {
        return normalizeCategoryV2(category);
    }
    if ('coverMedia' in category) {
        return normalizePlatformizedCategory(category);
    }
    return normalizeCategoryV3(category);
}
function normalizeCategoryV2(category) {
    return {
        id: category.id,
        label: category.menuLabel,
        slug: category.slug,
        menuLabel: category.menuLabel,
        description: category.description,
        cover: category.cover,
        rank: category.rank,
        coverType: category.coverType,
        slugs: category.slugs,
        language: category.language,
        postAmount: category.postAmount,
        translations: category.translations,
        seoData: category.seoData,
    };
}
function normalizeCategoryV3(category) {
    return {
        id: category.id,
        label: category.label,
        slug: category.slug,
        menuLabel: category.label,
        description: category.description,
        cover: category.coverImage,
        rank: category.displayPosition,
        coverType: category.coverImage ? 'image' : 'none',
        language: category.language,
        postAmount: category.postCount,
        seoData: category.seoData,
        slugs: [category.slug],
        translations: category.translations,
    };
}
function normalizePlatformizedCategory(category) {
    var _a;
    return {
        id: category.id,
        menuLabel: category.label,
        description: category.description,
        label: category.label,
        slug: category.slug,
        cover: category.coverMedia.image,
        rank: (_a = category.rank) !== null && _a !== void 0 ? _a : category.displayPosition,
        coverType: category.coverMedia.image ? 'image' : 'none',
        language: category.language,
        postAmount: category.postCount,
        seoData: category.seoData,
        slugs: category.slug ? [category.slug] : [],
        translations: [],
    };
}
